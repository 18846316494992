import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button } from "../buttons";
import { RichText } from "../textFields";
import { shouldForwardProp } from "../utils";
import { VerticalText } from "./VerticalText";

type PpHeroContainer = {
  $hasImage: boolean;
};

type PpFullHeight = {
  $fullHeight: boolean;
};

export const HeroContainer = styled("div", {
  shouldForwardProp,
})<PpHeroContainer>`
  ${({ $hasImage, theme }): SerializedStyles => css`
    ${theme.cssMixins.colHCentered};
    position: relative;
    width: 100vw;
    align-items: center;
    ${!$hasImage &&
    css`
      ${theme.cssMixins.heroLinearGradient}
    `}
    ${theme.breakpoints.up("sm")} {
      min-height: 350px;
    }
  `}
`;

export const HeroImage = styled(GatsbyImage)`
  ${({ theme }): SerializedStyles => css`
    position: absolute !important;
    ${theme.cssMixins.stretchAll};
    &:after {
      content: "";
      position: absolute;
      ${theme.cssMixins.stretchAll};
      ${theme.cssMixins.heroImgLinearGradient};
    }
  `}
`;

export const CTAButtons = styled(Stack)`
  ${({ theme }): SerializedStyles => css`
    ${theme.breakpoints.up("sm")} {
      margin-top: ${theme.spacing(2)};
    }
    flex-wrap: wrap;
    > a {
      margin: 8px !important;
    }
  `}
`;

export const SecondaryButton = styled(Button)`
  ${({ theme }): SerializedStyles => css`
    &:hover {
      ${theme.cssMixins.heroSecondaryButtonBackgroundHover};
    }
    ${theme.cssMixins.heroSecondaryButtonBackground};
  `}
`;

export const EmptyElement = styled("div")`
  height: 100%;
`;

export const TextContent = styled(Stack, { shouldForwardProp })<PpFullHeight>`
  ${({ $fullHeight, theme }): SerializedStyles => css`
    z-index: 100;
    height: 100%;
    text-align: center;
    padding: ${$fullHeight
      ? `0 ${theme.spacing(3)}`
      : `calc(${theme.cssMixins.appHeaderHeightMobile} + ${theme.spacing(
          8
        )}) ${theme.spacing(3)} ${theme.spacing(10)}`};
    ${theme.breakpoints.up("sm")} {
      padding: ${$fullHeight
        ? "0"
        : `calc(${theme.cssMixins.appHeaderHeightDesktop} + ${theme.spacing(
            8
          )}) 0 ${theme.spacing(10)}`};
      max-width: 70%;
    }
    ${theme.breakpoints.up("lg")} {
      max-width: 55%;
    }
    ${theme.breakpoints.up("xl")} {
      max-width: 1000px;
    }
  `}
`;

export const Headline = styled(RichText)`
  ${({ theme }): SerializedStyles => css`
    margin-bottom: ${theme.spacing(1)};
  `}
`;

export const Description = styled(RichText)`
  ${({ theme }): SerializedStyles => css`
    text-shadow: ${theme.shadows[4]};
  `}
`;

export const VerticalTextContainer = styled("div")`
  ${({ theme }): SerializedStyles => css`
    z-index: 100;
    ${theme.cssMixins.rowCentered};
    height: 100%;
  `}
`;

export const StyledVerticalText = styled(VerticalText, {
  shouldForwardProp,
})<PpFullHeight>`
  ${({ $fullHeight }): SerializedStyles => css`
    display: ${$fullHeight ? "flex" : "none"};
  `}
`;
