import React from "react";
import { TypographyProps } from "@mui/material";
import { Dots, Text, VerticalLineContainer } from "./styles";

type PpVerticalText = {
  text: string;
  variant: TypographyProps["variant"];
};

export function VerticalText({
  text,
  variant = "inherit",
  ...rest
}: PpVerticalText): React.ReactElement {
  return (
    <VerticalLineContainer {...rest}>
      <Text color="site.hero.verticalText" variant={variant}>
        {text}
      </Text>
      <Dots />
    </VerticalLineContainer>
  );
}
