import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import { Button } from "../buttons";
import { Link } from "../links";
import {
  CTAButtons,
  Description,
  EmptyElement,
  Headline,
  HeroContainer,
  HeroImage,
  SecondaryButton,
  StyledVerticalText,
  TextContent,
  VerticalTextContainer,
} from "./styles";

type TpButton = {
  title: string;
  url: string;
};

export type PpHero = {
  description?: string;
  hasAlertBar?: boolean;
  headline: string;
  heroType?: string;
  imageSrc?: ImageDataLike | null;
  primaryButton?: TpButton | null;
  secondaryButton?: TpButton | null;
  verticalText?: string;
};

export function Hero({
  description,
  hasAlertBar = false,
  headline,
  heroType,
  imageSrc,
  primaryButton,
  secondaryButton,
  verticalText,
}: PpHero): React.ReactElement {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const fullHeight = heroType === "full";

  function heroContainerHeight(): string {
    if (!fullHeight) return "auto";
    if (isMobile) {
      return hasAlertBar
        ? `calc(100vh - ${theme.cssMixins.alertBarHeightMobile})`
        : "100vh";
    }
    return hasAlertBar
      ? `calc(100vh - ${theme.cssMixins.alertBarHeightDesktop})`
      : "100vh";
  }

  return (
    <HeroContainer
      $hasImage={!!imageSrc}
      style={{ height: heroContainerHeight() }}
    >
      {imageSrc && (
        <HeroImage alt={`${headline}-hero`} image={getImage(imageSrc)} />
      )}
      <EmptyElement />
      <TextContent
        $fullHeight={fullHeight}
        alignItems="center"
        justifyContent="center"
      >
        <Headline color="primary.contrastText" text={headline} variant="h1" />
        {description && (
          <Description
            gutterBottom
            color="primary.contrastText"
            text={description}
            variant="hero-desc"
          />
        )}
        {(primaryButton || secondaryButton) && (
          <CTAButtons
            alignItems="center"
            direction="row"
            justifyContent="center"
          >
            {secondaryButton && (
              <Link to={secondaryButton.url}>
                <SecondaryButton color="secondary" variant="outlined">
                  {secondaryButton.title}
                </SecondaryButton>
              </Link>
            )}
            {primaryButton && (
              <Link to={primaryButton.url}>
                <Button color="secondary" variant="contained">
                  {primaryButton.title}
                </Button>
              </Link>
            )}
          </CTAButtons>
        )}
      </TextContent>
      {verticalText && (
        <VerticalTextContainer>
          <StyledVerticalText
            $fullHeight={fullHeight}
            text={verticalText}
            variant="vertical-text"
          />
        </VerticalTextContainer>
      )}
    </HeroContainer>
  );
}
