import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const VerticalLineContainer = styled("div")`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.colVCentered};
  `}
`;

export const Text = styled(Typography)`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.noSelect};
    ${theme.cssMixins.verticalText};
  `}
`;

export const Dots = styled("div")`
  ${({ theme }): SerializedStyles => css`
    margin-top: ${theme.spacing(1)};
    height: 35px;
    width: 1px;
    background-image: linear-gradient(
      ${theme.palette.site.hero.verticalText} 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-size: 1px 10px;
    background-repeat: repeat-y;
    ${theme.breakpoints.up("xl")} {
      height: 56px;
    }
  `}
`;
